import styled from 'styled-components';

export const ModalBody = styled.div`
  padding: 32px;

  .share-icon {
    width: 50px;
    height: 50px;
    margin: 52px auto 20px;
    display: block;
    color: #C0C3D1;
  }

  .share-kicker {
    margin: 0 0 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1C3EFF;
  }

  .share-heading {
    margin: 0;
    font-weight: 700;
    font-size: 35.16px;
    line-height: 1.1;
    text-align: center;
    letter-spacing: -0.3px;
  }
`;

export const ShareButtons = styled.div`
  display: flex;
  justify-content: center;
  padding: 54px 20px 70px;
  margin: 0;
  width: 100%;
  border-bottom-left-radius: 5.4px;
  border-bottom-right-radius: 5.4px;

  .icons-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .icon-button {
      width: 72px;
      height: 72px;
      position: relative;
      background-color: #D8DAE6;
      transition: background-color 0.3s ease 0s;

      &:hover {
        background-color: var(--accent-color);
      }

      .icon {
        display: block;
        position: absolute;
        width: 36px;
        height: 36px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
  
      &.url {
        color: var(--primary-color);

        &.copied {
          pointer-events: none;
        }
      }
  
      &.facebook {
        color: var(--primary-color);
      }
  
      &.twitter {
        color: var(--primary-color);
      }
  
      &.linkedin {
        color: var(--primary-color);
  
        .icon {
          width: 34px;
          height: 34px;
        }
      }
    }
  }
`;
